@use "./../../common/variables" as v;
@use "./../../common/mixins" as m;
@forward "./../../common/placeholders";

header {
    position: relative;
    @extend %neutral;
    // @extend %neutral-bold;

    // font-size: 25px;
    // @include font-size(35px);
    @include m.adaptiv-font(26, 26);
}

.header {
    @extend %flex-col;
    justify-content: space-between;
    height: 100vh;
    min-height: 100vh;
    min-height: -webkit-fill-availbale; // height: calc(var(--vh, 1vh) * 100);
    @extend %color--primary-white;

    a {
        @extend %color--primary-white;
    }

    &__row {
        @extend %flex;
        width: 100%;

        &--between {
            justify-content: space-between;
        }
    }
}
