@forward "./../../../../common/base";
@forward "./../../../../common/placeholders";

.contacts {
    &__wrapper {
        @extend .wrapper;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__content {
        width: 100%;
    }

    &__title {
        text-transform: uppercase;
        text-align: center;
    }

    &__list {
        padding: 25px;
        @extend %flex-row;
        justify-content: space-around;
        list-style: none;
        padding: 0;

        &-item {
            @extend %flex-col;
            align-items: center;

            span {
                display: block;

                img {
                    width: 50px;
                    height: 50px;
                }
            }

            a {
                text-decoration: none;
            }
        }
    }
}
