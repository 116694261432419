@use "./../../../common/mixins" as m;

.header-nav {
    @include m.media(
        (
            max: 1023,
        ),
        (
            position: fixed,
            z-index: -1,
            bottom: 0,
            width: 100%,
            top: 0,
            height: 100vh,
            left: 0,
            box-sizing: border-box,
            background: rgba(255, 255, 255, 0),
            transition: background 0.3s,
        )
    );

    &--open {
        @include m.media(
            (
                max: 1023,
            ),
            (
                z-index: 3,
                background: #282c35,
                transition: background 0.3s,
            )
        );
    }

    &__list {
        @include m.media(
            (
                max: 1023,
            ),
            (
                // position: fixed,
                // margin: 0,
                // top: 0,
                // left: 0,
                // bottom: 0,
                // right: 0,
                justify-content: center,
                flex-direction: column,
                align-items: center,
                // height: 100%,
                opacity: 0,
                // transform: scale(0),
                // transition: 0.3s,
            )
        );
    }
}
