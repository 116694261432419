@use "./../../../common/mixins" as m;

.header-button {
    @include m.media(
        (
            max: 1023,
        ),
        (
            margin: 0 auto 172px auto,
        )
    );
    @include m.media(
        (
            max: 1023,
            orientation: landscape,
        ),
        (
            display: none,
            margin: 0 auto 36px auto,
        )
    );
}
