@forward "./../../../common/placeholders";

.header-nav {
    $parent: &;

    z-index: 2;
    @extend %flex;
    flex-grow: 1;
    align-self: center;
    justify-content: center;
    padding: 0 25px;

    &--open {
        #{$parent}__list {
            opacity: 1;
        }
    }

    &__list {
        @extend %flex-row;
        gap: 50px;
        padding: 0;
        list-style: none;
    }
}
