@use "./../../../../common/mixins" as m;

.contacts {
    &__content {
        width: 100%;
    }

    &__title {
        text-transform: uppercase;
        text-align: center;
    }

    &__list {
        padding: 25px;
        justify-content: space-around;
        list-style: none;
        padding: 0;

        @include m.media(
            (
                max: 1023,
            ),
            (
                flex-wrap: wrap,
                gap: 35px,
            )
        );
        @include m.media(
            (
                max: 620,
            ),
            (
                flex-wrap: wrap,
                gap: 35px,
            )
        );
        &-item {
            @include m.media(
                (
                    max: 1023,
                ),
                (
                    width: calc((100% / 2) - 35px),
                )
            );
            @include m.media(
                (
                    max: 620,
                ),
                (
                    width: 100%,
                )
            );
            align-items: center;

            span {
                display: block;
            }

            a {
                text-decoration: none;
            }
        }
    }
}
