@use "./../../../../common/mixins" as m;
@use "./../../../../common/variables" as v;
@forward "./../../../../common/base";
@forward "./../../../../common/placeholders";

.about {
    &__wrapper {
        @extend .wrapper;
    }

    &__title {
        text-align: center;
        text-transform: uppercase;
        padding-top: 25px;
    }

    &__content {
        @extend %flex-row;
        justify-content: space-around;
        align-items: stretch;
        width: 100%;
        padding: 50px 25px;
        box-sizing: border-box;
        @include m.adaptiv-font(36, 26);

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 25px;
        }

        &--reverse {
            flex-direction: row-reverse;

            .about__item:nth-child(1) {
                align-items: flex-start;
                justify-content: center;
                display: flex;
            }
            .about__item:nth-child(2) {
                justify-content: center;
                border-right: 1px solid white;
                border-left: unset;
                gap: 1em;
                padding: 1em 30px 1em 0;

                @media screen and (max-width: 768px) {
                    padding: 1em 30px 1em 30px;
                    border-left: 1px solid white;
                    box-sizing: border-box;
                }
            }
        }
    }

    &__item {
        @extend %flex-col;
        width: calc((100% / 2) - 50px);

        @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
        }

        &-img {
            @extend %flex;
            width: fit-content;
            // box-shadow: 0 0px 30px -10px v.$primary-orange;
            box-shadow: 0 0px 5px 0px v.$primary-white;
            box-sizing: border-box;
            border-radius: 50%;
            // clip-path: circle(50%);
            box-sizing: border-box;

            picture {
                @extend %flex;
            }

            img {
                shape-outside: circle(250px at 50% 50%) padding-box;
                max-width: 400px;
                // max-height: 400px;
                width: 100%;
                border-radius: 50%;
                aspect-ratio: 1;
                object-fit: cover;

                @media screen and (max-width: 768px) {
                    max-width: 200px;
                }
            }
        }

        &-title {
            strong {
                @extend %color--primary-orange;
                font-weight: 400;
            }
        }

        &-text {
            strong {
                @extend %color--primary-orange;
                font-weight: 400;
            }
            p {
                margin: 0;
            }
        }
    }

    &__item:nth-child(1) {
        align-items: flex-end;
        justify-content: center;
        display: flex;
    }

    &__item:nth-child(2) {
        justify-content: center;
        border-left: 1px solid white;
        padding-left: 30px;
        gap: 1em;
        padding: 1em 0px 1em 30px;

        @media screen and (max-width: 768px) {
            padding: 1em 30px 1em 30px;
            // border-right: 1px solid white;
            border: unset;
            box-sizing: border-box;
        }
    }
}
