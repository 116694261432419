// COLORS PALITE

$primary-dark: #282c35;
$secondary-dark: #7f8186;
$tertiary-dark: #e2e2e2;
$primary-white: #e2e2e2;
$primary-violet: #3f3b55;
$secondary-violet: #964b77;
$primary-pink: #c55172;
$secondary-pink: #eb605e;
$primary-orange: #ff7e3f;
$primary-yellow: #ffa600;

$colors: (
    primary-dark: $primary-dark,
    secondary-dark: $secondary-dark,
    tertiary-dark: $tertiary-dark,
    primary-white: $primary-white,
    primary-violet: $primary-violet,
    secondary-violet: $secondary-violet,
    primary-pink: $primary-pink,
    secondary-pink: $secondary-pink,
    primary-orange: $primary-orange,
    primary-yellow: $primary-yellow,
);
