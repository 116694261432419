@forward './../../../../common/base';
@forward './../../../../common/placeholders';

.skills {

    &__wrapper {
        @extend .wrapper;
    }

    &__title {
        text-align: center;
        text-transform: uppercase;
    }

    &__content {}
}