@use "./../variables" as v;
@use "./../mixins" as m;
@forward "./../placeholders";

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: v.$primary-violet v.$primary-dark;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    @extend %background--primary-dark;
    // border-left: 1px solid v.$primary-orange;
}

*::-webkit-scrollbar-thumb {
    @extend %background--primary-violet;
    // border-left: 1px solid v.$primary-orange;
}

:root {
    --vh: 1vh;
}

html {
    scroll-behavior: smooth;
}

// body {
//     width: 100vw;
// }

html,
body {
    margin: 0;
    padding: 0;
    @extend %doloman;
    @extend %background--primary-dark;
    @extend %color--primary-white;
}

main {
    @include m.adaptiv-font(36, 26);

    a {
        @extend %color--primary-orange;

        &:visited {
            @extend %color--primary-orange;
        }
    }
}

footer {
    @include m.adaptiv-font(16, 16);

    a {
        @extend %color--primary-orange;

        &:visited {
            @extend %color--primary-orange;
        }
    }
}

a {
    position: relative;
    text-decoration: none;
    padding: 0.15em 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    /* Fade in */
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.05em;
        @extend %background--primary-orange;
        border-radius: 2px;
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
    }

    &:visited {
        @extend %color--primary-white;
    }

    &:hover::after {
        transform: scale(1);
    }

    &::after {
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
    }
}

h2 {
    @include m.adaptiv-font(46, 36);
}

h3 {
    @include m.adaptiv-font(30, 20);
}

.loading * {
    transition: 0s !important;
    transition-delay: 0s !important;
}

.vh {
    height: calc(var(--vh, 1vh) * 100);
}

.scroll-hidden {
    overflow: hidden;
}
