@use "./mixins" as m;

// FLEX

%flex {
    display: flex;
}

%flex-row {
    @extend %flex;
    flex-direction: row;
}

%flex-col {
    @extend %flex;
    flex-direction: column;
}

// BG, COLOR, BORDER

@include m.set_modifier_tag();

// FONTS

%neutral {
    font-family: "Neutral Face";
}

%neutral-bold {
    font-family: "Neutral Face Bold";
}

%doloman {
    font-family: "Doloman";
}
