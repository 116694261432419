@use "./../../../common/variables" as v;

.header-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: rgb(40, 44, 53);
        background: linear-gradient(
            360deg,
            rgba(40, 44, 53, 1) 0%,
            rgba(40, 44, 53, 0) 100%
        );
    }

    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: v.$primary-dark;
        opacity: 0.8;
    }
}
