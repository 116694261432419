@use "./../../../../common/variables" as v;
@use "./../../../../common/mixins" as m;
@forward "./../../../../common/placeholders";
@forward "./../../../../common/base";

.portfolio {
    &__wrapper {
        @extend .wrapper;
    }

    &__title {
        text-transform: uppercase;
        text-align: center;
        padding-top: 25px;
    }

    &-list {
        @extend %flex;
        flex-wrap: wrap;
        width: 100%;

        &__item {
            @extend %flex-col;
            justify-content: center;
            align-items: center;
            width: 33.33%;
        }
    }
}

$breakpoint: 1023px;
// You also need to change the JS breakpoint.

.swiper-container {
    overflow: hidden;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: $breakpoint) {
        min-height: 320px;
    }
    &-wrapper {
        display: flex;
        flex-flow: column nowrap;
        @media (min-width: $breakpoint) {
            flex-flow: row nowrap;
            height: 100%;
        }
        height: auto;
        width: 100%;
    }
}

.swiper-button {
    &-next,
    &-prev {
        color: #000;
        z-index: 1;
    }
}

.swiper-slide {
    position: relative;
    text-align: center;
    background-size: cover;
    background-position: center;
    background-color: #fff;

    /* Center slide text vertically */
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    /* Slide content */

    &__box {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        // height: 30%;
        bottom: 0;
        left: 0;
        padding: 15px 15px;
        box-sizing: border-box;
        background: #282c35b4;
    }

    picture {
        @extend %flex;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: auto;
            aspect-ratio: unset;
            object-fit: unset;
            object-position: unset;
            @media (min-width: $breakpoint) {
                aspect-ratio: 1;
                object-fit: cover;
                object-position: top;
            }
        }
    }
    .description,
    .title {
        display: block;
        opacity: 0;
        transition: 0.5s ease 0.5s;
    }
    &-active {
        .description,
        .title {
            opacity: 1;
        }
        .title {
            width: 100%;
            font-weight: 600;
            margin-bottom: 0.5rem;
            // font-size: 34px;
            @include m.adaptiv-font(34, 22);
            // color: #000;
            color: white;
            transition: opacity 0.5s ease 0.5s;
        }
        .description {
            font-weight: 600;
            width: 100%;
            // font-size: 24px;
            @include m.adaptiv-font(24, 16);
            // color: #777;
            color: white;
            transition: opacity 0.5s ease 0.75s;
        }
    }
}

.gallery-top {
    position: relative;
    width: 100%;
    height: auto;
    @media (min-width: $breakpoint) {
        width: 80%;
        height: 100vh;
        max-height: 750px;
        margin-right: 10px;
    }
}

.gallery-thumbs {
    width: 100%;
    padding-top: 10px;
    // height: 25vh;
    height: auto;
    @media (min-width: $breakpoint) {
        width: 20%;
        height: 100vh;
        max-height: 750px;
        padding: 0;
    }
    .swiper-wrapper {
        flex-direction: row;
        height: auto;
        @media (min-width: $breakpoint) {
            flex-direction: column;
            height: 100%;
        }
    }
    .swiper-slide {
        width: 25%;
        flex-flow: row nowrap;
        @media (min-width: $breakpoint) {
            flex-flow: column nowrap;
            width: 100%;
            height: 100%;
        }
        height: auto;
        opacity: 0.1;
        cursor: pointer;
    }
    .swiper-slide-thumb-active {
        opacity: 1;
    }
}
