.header-button {
    // position: absolute;
    // bottom: 0;
    // left: 50%;
    // transform: translate(-50%, -36px);
    // transform: translate(-50%, -36px);
    margin: 1rem 0;
    position: relative;
    display: block;
    width: 33px;
    height: 59px;
    border: 1px solid #fff;
    border-radius: 21px;
    background-color: transparent;
    padding: 0;
    margin: 0 auto 36px auto;
    outline: none;
    cursor: pointer;

    &::before {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        content: "";
        width: 6px;
        height: 6px;
        background-color: #fff;
        border-radius: 50%;
        top: 10px;
        -webkit-animation: ani-mouse 2s linear infinite;
        animation: ani-mouse 2s linear infinite;
    }

    &::after {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        content: "scroll down";
        font-weight: 300;
        color: #fff;
        font-size: 9px;
        bottom: -24px;
        letter-spacing: 2px;
        text-transform: uppercase;
        white-space: nowrap;
    }

    @-webkit-keyframes ani-mouse {
        0% {
            transform: translate(-50%);
            opacity: 1;
        }
        33% {
            transform: translate(-50%, 15px);
            opacity: 1;
        }
        66% {
            transform: translate(-50%, 30px);
            opacity: 0;
        }
        to {
            transform: translate(-50%, 45px);
            opacity: 0;
        }
    }
    @keyframes ani-mouse {
        0% {
            transform: translate(-50%);
            opacity: 1;
        }
        33% {
            transform: translate(-50%, 15px);
            opacity: 1;
        }
        66% {
            transform: translate(-50%, 30px);
            opacity: 0;
        }
        to {
            transform: translate(-50%, 45px);
            opacity: 0;
        }
    }
}
