@use "./../../../common/mixins" as m;

.header-logo {
    @include m.media(
        (
            max: 1023,
        ),
        (
            margin: 0 auto,
        )
    );
}
