@use "./../../../common/variables" as v;
@forward "./../../../common/placeholders";

.header-burger {
    position: absolute;
    // z-index: 3;
    display: none;
    align-self: center;
    width: 100%;
    height: 100vh;

    &__wrapper {
        width: 50px;
        height: 50px;
    }

    &-tap {
        position: absolute;
        width: 52px;
        height: 52px;
        z-index: 6;
        box-sizing: border-box;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &__icon {
        position: absolute;
        z-index: 4;
        @extend %flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        @extend %border--primary-white;
        border-radius: 50%;
        border: 2px solid;
        transition-delay: 0.6s;
        transition: 0.6s;
        // opacity: 0;
        box-sizing: border-box;
        animation: span-center 0.6s linear;

        @keyframes span-center {
            0% {
                opacity: 0;
            }
            25% {
                opacity: 0;
            }
            75% {
                opacity: 0.5;
            }
            100% {
                opacity: 1;
            }
        }

        &--square {
            border-radius: 5px;
            box-sizing: border-box;
            animation: burger-right 0.6s linear;

            @keyframes burger-right {
                0% {
                    opacity: 0;
                }
                25% {
                    opacity: 0;
                }
                75% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        &--right {
            position: fixed;
            top: 25px;
            right: 25px;

            animation: span-right 0.3s linear;

            @keyframes span-right {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }

    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        opacity: 0;
        // transition: 0.3s;
        // transition-delay: 0.3s;
        box-sizing: border-box;
        transition: opacity 0.3s;

        &--show {
            opacity: 1;
        }

        i {
            position: relative;
            transition: 0.3s;
            transition-delay: 1.3s;
            width: 100%;

            &::before {
                position: absolute;
                width: 75%;
                margin: 0 auto;
                left: 0;
                right: 0;
                border-radius: 3px;
                box-sizing: border-box;
                height: 3px;
                content: "";
                transform: rotate(-45deg);
                background: #ffffff;
            }

            &::after {
                position: absolute;
                width: 75%;
                margin: 0 auto;
                left: 0;
                right: 0;
                border-radius: 3px;
                box-sizing: border-box;
                height: 3px;
                content: "";
                transform: rotate(45deg);
                background: #ffffff;
            }
        }
    }
}
