@font-face {
    font-family: "Neutral Face";
    src: local("Neutral Face"),
        url("../fonts/neutral_face/NeutralFace.otf") format("truetype");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Neutral Face Bold";
    src: local("Neutral Face"),
        url("../fonts/neutral_face/NeutralFace-Bold.otf") format("truetype");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Doloman";
    src: local("DolomanPavljenko"),
        url("../fonts/doloman/DolomanPavljenko.otf") format("truetype");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
}
