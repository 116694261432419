@use "./../../../common/mixins" as m;

.header-burger {
    @include m.media(
        (
            max: 1023,
        ),
        (
            display: flex,
            justify-content: center,
            align-items: center,
        )
    );

    &__icon {
        @include m.media(
            (
                min: 1024,
            ),
            (
                opacity: 0,
                z-index: 4,
            )
        );
    }
}
