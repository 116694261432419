@use "./../../../../../node_modules/rfs/scss" as rfs;

html,
body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

header,
footer {
    flex: 0 0 auto;
}

main {
    flex: 1 0 auto;
}

// section {
//     height: 100vh;
// }

.wrapper {
    width: 100%;
    @include rfs.padding(0 25px);
    box-sizing: border-box;
}
